<template>
    <div class="object_detail">
        <label class="cdi_edit" @click="changeView">{{ field.label }}</label>
        <div class="form-control">
            <a href="#" v-if="field.clickableValue" @click.prevent="changeView">
                {{ val }}
            </a>
            <span v-else>{{ val }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "DetailsView",
    props: ['field', 'val'],
    methods: {
        changeView: function() {
            if (!this.field.emitIfPositive) {
                this.$emit('view', this.field.view);
            }

            if (this.field.emitIfPositive && this.val !== 0) {
                this.$emit('view', this.field.view);
            }
        }
    }
}
</script>

<style scoped>
    .form-control {
        white-space: pre-wrap;
        overflow: visible;
    }
</style>